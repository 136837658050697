import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import Typography from "../components/typography"
import CardPartners from "../components/cards/cardPartners"
import { CardMoreInfo } from "../components/cards/requestDemo/cardMoreInfo"
import { HoverButton } from "../components/button"
import { MobileMediaQuery } from "../styles/constants"
import colors from "../styles/colors"
import { useNavigate } from "../components/link"
import { Calendly } from "../components/calendly"

const FullWidthAlignment = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${p => (p.rightComponent ? "space-between" : "center")};
  text-align: ${p => (p.centerText ? "center" : "left")};

  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: center;
  }
`
const Left = styled.div`
  max-width: ${p => (p.leftMaxWidth ? p.leftMaxWidth : "970px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MobileMediaQuery} {
    text-align: center;
    margin-bottom: 3rem;
  }
`
const Right = styled.div`
  margin-left: 4rem;
  flex: 1;

  ${MobileMediaQuery} {
    margin-left: 0;
  }
`
const Button = styled(HoverButton)`
  margin-top: 4rem;
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
  font-weight: 400;
`

const FullWidthCard = styled(Card)`
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const FullWidth = React.forwardRef(
  (
    {
      title,
      description,
      variant,
      titleColor,
      leftMaxWidth,
      centerText = false,
      rightComponent,
      button,
    },
    ref
  ) => (
    <FullWidthCard
      variant={variant || "lightpurple"}
      style={{
        width: "100vw",
        maxWidth: "100vw",
        borderRadius: 0,
        marginLeft: "-4px",
        marginRight: "-4px",
      }}
    >
      <FullWidthAlignment
        centerText={centerText}
        rightComponent={rightComponent}
      >
        <Left leftMaxWidth={leftMaxWidth}>
          <Typography
            variant="h2"
            style={{
              color: titleColor ? colors[titleColor] : colors.primary,
              marginBottom: "4rem",
            }}
          >
            {title}
          </Typography>
          <Typography variant="h6">{description}</Typography>
          {button}
        </Left>
        {rightComponent && <Right ref={ref}>{rightComponent}</Right>}
      </FullWidthAlignment>
    </FullWidthCard>
  )
)

const ImpactAnalysis = () => {
  const scrollRef = React.createRef()
  const navigate = useNavigate()

  const scrollToCalendar = React.useCallback(() => {
    const top = scrollRef.current.getBoundingClientRect().top
    const scrollValue = top + window.pageYOffset
    window.scrollTo({ top: scrollValue - 80, behavior: "smooth" })
  }, [scrollRef])

  return (
    <Layout bg="white" newsletter={null}>
      <SEO
        title="Free impact analysis"
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <FullWidth
        centerText
        title="Get your free impact analysis"
        button={
          <Button onClick={scrollToCalendar}>
            Book time with a Specialist
          </Button>
        }
        description={
          <>
            Want to know how Mavenoid can help your company? Let us show you.
            <br />
            <br />
            In our free impact analysis, we’ll analyze both ticket data and
            publicly available information to provide insight into how Mavenoid
            can help you automate troubleshooting, reduce overhead, and improve
            your customer support experience.
          </>
        }
      />
      <CardPartners variant="white" />
      <FullWidth
        ref={scrollRef}
        titleColor="lightpurple"
        variant="primary"
        leftMaxWidth="600px"
        title="Get a Personalized Assessment from our Team"
        description={
          <>
            Schedule a call with our specialists who will work with you to
            identify areas of improvement within your support setup.
            <br />
            <br />
            Our team will gather information, build a personal assessment, and
            walk you through our recommendations— all for free.
          </>
        }
        rightComponent={
          <Calendly url="https://calendly.com/jonathankoo/free-impact-analysis" />
        }
      />
      <CardMoreInfo
        title={
          <>
            Not ready for a full assessment? <br />
            <span style={{ color: colors.primary }}>
              Try our free ROI Calculator for a preview.
            </span>
          </>
        }
        buttonText={<>Try the ROI Calculator</>}
        onClick={() => navigate("/roi")}
      />
    </Layout>
  )
}

export default ImpactAnalysis
